import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"   viewBox="0 0 300.000000 286.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,286.000000) scale(0.100000,-0.100000)">
 

 <path d="M1270 2843 c-130 -15 -331 -78 -465 -145 -389 -196 -660 -530 -765
-947 -19 -75 -40 -293 -28 -281 8 8 37 131 44 184 7 60 48 207 74 263 11 24
20 47 20 53 0 23 127 240 140 240 6 0 10 6 10 13 0 31 243 283 261 271 8 -4 9
-3 5 4 -4 6 4 16 16 23 13 7 27 18 31 25 4 8 15 14 22 14 8 0 15 4 15 9 0 5
13 14 30 21 16 7 39 21 51 31 12 10 26 16 30 13 5 -3 9 -1 9 3 0 10 118 66
138 66 6 0 10 3 7 6 -11 10 198 72 330 98 148 29 186 43 110 41 -27 -1 -66 -3
-85 -5z"/>
<path d="M1609 2840 c13 -6 66 -17 117 -25 51 -9 146 -31 211 -51 134 -41 316
-122 306 -137 -3 -6 -1 -7 5 -3 11 7 82 -31 82 -44 0 -5 9 -12 21 -15 24 -8
34 -16 138 -106 153 -133 263 -278 345 -455 58 -123 115 -321 117 -406 0 -18
9 -51 20 -73 l19 -40 -6 85 c-14 213 -102 452 -237 648 -109 159 -303 337
-475 436 -178 103 -420 180 -594 191 -69 4 -87 3 -69 -5z"/>
<path d="M1365 2708 c-49 -5 -94 -10 -100 -13 -5 -3 22 -3 60 0 39 3 117 5
174 5 57 0 102 2 100 5 -9 9 -144 10 -234 3z"/>
<path d="M1750 2687 c0 -7 141 -39 147 -33 2 2 -19 9 -49 15 -29 7 -63 14 -75
17 -13 4 -23 4 -23 1z"/>
<path d="M1478 2663 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M1010 2628 c-30 -11 -63 -21 -72 -21 -9 -1 -14 -5 -12 -9 3 -4 -4 -8
-15 -8 -11 0 -22 -4 -26 -9 -3 -5 -36 -25 -72 -43 -70 -34 -82 -41 -114 -70
-22 -21 -33 -23 -24 -5 4 6 -2 1 -14 -13 -11 -14 -25 -25 -30 -25 -6 0 -11 -4
-10 -8 0 -5 -13 -18 -28 -30 -15 -12 -32 -27 -36 -34 -4 -7 -15 -13 -22 -13
-8 0 -15 -4 -15 -10 0 -15 -37 -49 -46 -42 -4 4 -4 2 -1 -5 4 -6 -6 -22 -23
-36 -16 -14 -30 -30 -30 -36 0 -6 -4 -11 -9 -11 -6 0 -20 -18 -32 -40 -13 -22
-27 -40 -31 -40 -5 0 -6 -5 -3 -11 4 -5 -2 -18 -12 -27 -20 -17 -33 -41 -70
-122 -21 -45 -34 -76 -39 -95 -1 -5 -12 -35 -23 -65 -12 -30 -21 -65 -21 -78
0 -15 -4 -21 -11 -16 -9 5 -10 -3 -5 -30 4 -20 4 -42 -1 -49 -15 -24 -17 -54
-17 -197 1 -110 7 -169 23 -245 13 -55 24 -113 25 -129 1 -17 8 -32 14 -34 7
-2 12 -10 12 -18 0 -8 9 -33 21 -56 11 -22 18 -49 16 -59 -3 -11 -1 -16 4 -13
6 3 17 -13 26 -35 9 -23 20 -41 25 -41 4 0 8 -7 8 -15 0 -8 5 -15 10 -15 6 0
10 -6 10 -13 0 -7 16 -32 35 -54 19 -23 35 -43 35 -45 0 -7 84 -100 109 -122
13 -12 44 -40 67 -64 23 -23 46 -42 50 -42 4 0 14 -7 23 -16 26 -25 121 -84
136 -84 8 0 18 -6 22 -13 4 -6 19 -17 33 -24 59 -28 120 -53 129 -53 6 0 11
-4 11 -10 0 -5 9 -10 21 -10 11 0 28 -4 38 -10 38 -20 187 -54 286 -65 55 -6
273 -7 310 -1 17 3 56 8 88 12 32 4 60 11 63 15 3 5 20 9 38 9 18 0 36 3 40 7
3 4 16 8 27 10 11 2 33 10 48 17 15 8 39 16 52 18 13 2 34 9 45 16 12 6 25 8
29 4 5 -4 5 -2 2 4 -4 7 4 15 20 19 15 3 68 33 118 66 51 32 96 59 102 59 6 0
36 24 67 53 31 30 56 52 56 50 0 -2 9 6 20 17 11 11 19 21 18 23 -1 1 26 31
60 66 34 36 62 71 62 78 0 7 5 13 12 13 6 0 9 3 6 6 -3 3 5 17 19 32 14 15 30
39 35 55 6 15 14 27 19 27 5 0 9 9 9 19 0 11 7 26 15 35 9 8 22 35 29 58 8 24
23 68 34 99 12 30 31 104 43 165 19 95 21 129 15 269 -3 88 -12 187 -20 220
-17 76 -62 210 -82 244 l-15 26 7 -25 c4 -14 12 -36 19 -50 21 -43 53 -161 70
-254 39 -227 1 -487 -104 -712 -87 -185 -259 -388 -424 -502 -144 -99 -323
-177 -503 -218 -109 -25 -390 -30 -516 -9 -479 80 -887 417 -1031 853 -55 165
-70 270 -64 442 13 328 128 591 363 827 141 142 333 264 502 318 28 10 52 19
52 21 0 8 -27 2 -80 -18z"/>
<path d="M1250 2643 c-92 -15 -239 -65 -345 -116 -577 -281 -842 -932 -619
-1524 73 -194 245 -417 423 -547 189 -137 419 -223 665 -248 568 -56 1114 263
1327 773 55 132 79 249 86 409 3 80 2 141 -3 137 -4 -4 -7 -24 -6 -44 4 -55
-17 -263 -31 -303 -6 -19 -10 -41 -8 -49 1 -7 -2 -10 -8 -6 -6 3 -9 0 -5 -8 3
-8 -3 -34 -14 -58 -11 -24 -25 -57 -32 -74 -8 -16 -14 -32 -14 -35 -4 -22 -61
-118 -68 -114 -4 3 -8 -3 -8 -14 0 -10 -9 -27 -20 -37 -11 -10 -20 -23 -20
-29 0 -6 -25 -37 -55 -68 -30 -32 -55 -60 -55 -63 0 -9 -80 -85 -89 -85 -5 0
-25 -16 -44 -35 -19 -19 -42 -35 -50 -35 -8 0 -17 -6 -20 -13 -3 -7 -36 -28
-73 -46 -38 -19 -74 -40 -81 -48 -6 -8 -17 -16 -25 -17 -7 -2 -26 -8 -41 -14
-16 -7 -34 -12 -42 -12 -8 0 -17 -4 -20 -10 -3 -5 -17 -10 -31 -10 -13 0 -24
-5 -24 -11 0 -5 -8 -9 -17 -8 -31 3 -103 -13 -97 -22 3 -5 -3 -9 -13 -9 -10 0
-63 -5 -118 -11 -189 -19 -303 -11 -485 34 -30 7 -62 16 -70 20 -8 3 -43 16
-78 28 -34 12 -65 26 -68 30 -3 5 -13 9 -23 9 -10 0 -24 7 -31 15 -7 8 -21 15
-31 15 -11 0 -19 5 -19 12 0 6 -3 8 -7 5 -3 -4 -18 3 -31 15 -14 13 -21 16
-16 8 4 -8 -11 1 -35 20 -23 19 -50 40 -58 45 -8 6 -27 21 -42 35 -15 14 -44
40 -65 57 -20 18 -33 33 -29 33 4 0 1 5 -8 10 -9 5 -36 35 -60 67 -24 32 -52
67 -62 77 -9 11 -17 25 -17 32 0 7 -4 14 -9 16 -5 2 -11 12 -13 22 -4 26 56
44 79 24 24 -20 116 -26 129 -9 11 14 18 16 84 21 74 5 110 0 128 -18 25 -25
75 -22 105 6 21 19 28 21 53 12 15 -6 45 -8 65 -5 49 9 159 9 195 1 22 -5 35
-18 54 -54 62 -120 86 -149 152 -181 73 -36 123 -46 233 -45 111 0 150 11 222
58 52 34 68 51 94 105 29 58 31 70 27 135 -5 77 -20 113 -67 164 -17 18 -31
37 -31 42 0 4 27 13 59 18 35 6 68 19 80 31 20 20 20 21 -9 127 -9 33 -8 59 5
130 13 72 16 147 16 384 -1 162 -4 320 -7 350 -3 30 -7 69 -9 85 -4 42 -20 79
-57 133 -42 62 -89 112 -105 112 -7 0 -12 5 -11 11 2 6 -8 13 -21 15 -13 3
-29 11 -35 20 -7 8 -16 14 -21 13 -6 -2 -37 7 -69 19 -135 52 -387 34 -506
-35 -114 -67 -186 -180 -213 -334 -18 -106 -17 -737 2 -809 7 -25 15 -57 18
-71 2 -14 22 -49 43 -77 21 -28 37 -53 34 -56 -2 -3 -1 -4 2 -2 3 3 18 -6 33
-20 14 -14 43 -33 64 -42 26 -12 37 -23 36 -37 0 -20 0 -20 -8 0 -8 20 -8 20
-14 0 -7 -19 -15 -20 -262 -18 l-255 2 -35 26 c-36 28 -61 28 -91 -2 -23 -23
-62 -21 -116 5 -53 25 -103 23 -128 -6 -27 -31 -44 -15 -67 61 -27 88 -36 152
-36 240 0 37 -4 67 -9 67 -5 0 -7 4 -4 8 2 4 7 32 10 62 8 86 27 214 34 225 3
5 7 16 7 25 4 38 17 80 26 80 6 0 8 4 5 8 -6 10 59 148 74 157 5 3 10 14 10
24 0 9 9 27 20 39 11 12 20 26 20 31 0 6 6 14 13 18 7 4 20 19 29 33 9 14 33
42 52 63 20 21 36 42 36 48 0 5 4 9 9 9 5 0 33 23 62 50 29 28 59 50 66 50 8
0 11 4 8 10 -3 5 6 12 20 16 14 3 30 15 36 25 5 11 16 19 24 19 8 0 15 4 15 9
0 13 199 113 210 106 6 -3 10 -2 10 4 0 6 17 13 38 17 20 4 42 10 48 15 14 11
116 37 173 44 25 3 47 7 49 10 6 6 -40 4 -78 -2z m453 -112 c131 -34 209 -87
273 -187 59 -93 65 -135 65 -517 -1 -322 -6 -405 -31 -483 -9 -26 -6 -44 16
-103 15 -39 24 -74 22 -76 -3 -3 -38 -9 -79 -15 -58 -7 -84 -6 -115 5 -67 24
-154 18 -154 -11 0 -17 23 -28 90 -44 133 -31 200 -102 200 -212 0 -77 -18
-120 -74 -174 -111 -108 -288 -123 -465 -39 -41 20 -71 38 -68 41 3 3 -14 37
-39 75 -24 39 -42 74 -39 80 4 5 25 9 48 9 23 1 62 5 86 9 38 8 50 5 97 -19
92 -49 189 -52 182 -7 -3 23 -18 29 -143 63 -45 12 -69 26 -97 55 -21 22 -38
48 -38 59 0 23 -22 36 -75 43 -158 20 -280 135 -316 294 -6 27 -13 192 -16
368 -5 362 4 467 52 574 46 103 154 187 280 217 81 20 250 17 338 -5z m-1246
-1483 c13 -12 23 -28 23 -35 0 -12 3 -12 20 0 14 9 36 13 64 10 40 -5 43 -4
42 18 -1 19 5 25 27 27 23 3 27 0 27 -23 0 -16 4 -24 10 -20 6 4 22 -1 35 -9
23 -15 27 -15 51 1 25 16 28 16 55 0 16 -9 29 -12 29 -7 0 6 11 10 25 10 21 0
25 -5 27 -32 l1 -33 4 34 c4 31 6 33 36 28 18 -2 39 -1 48 4 9 4 33 6 53 2 34
-5 36 -8 36 -44 0 -22 5 -39 11 -39 7 0 8 11 4 30 -4 19 -2 36 5 45 14 17 50
20 50 6 0 -6 16 -7 35 -4 l34 5 -6 -36 c-4 -24 -2 -36 6 -36 6 0 11 16 11 35
0 30 3 35 24 35 22 0 25 -5 29 -47 2 -27 3 -15 1 25 l-2 72 29 0 c24 0 29 -4
29 -26 0 -15 4 -23 10 -19 6 4 22 -1 35 -9 18 -12 25 -25 25 -50 0 -49 -17
-57 -117 -58 -120 0 -123 0 -123 17 0 12 -6 11 -31 -7 -29 -22 -31 -22 -44 -5
-8 11 -15 13 -17 7 -7 -21 -78 -20 -99 1 -10 10 -21 19 -23 19 -2 0 -14 -16
-26 -35 -15 -25 -29 -35 -46 -35 -29 0 -42 24 -24 45 21 26 0 34 -25 9 -28
-28 -42 -30 -73 -8 -19 13 -25 13 -43 1 -13 -10 -34 -13 -60 -10 -29 4 -45 1
-58 -11 -27 -25 -96 -18 -97 9 -2 46 -4 47 -35 21 -38 -32 -70 -33 -104 -3
-20 16 -27 33 -28 60 -2 74 78 114 130 65z"/>
<path d="M1420 2520 c-10 -6 5 -8 45 -4 33 3 62 8 64 10 9 9 -95 3 -109 -6z"/>
<path d="M1590 2520 c0 -7 7 -10 15 -6 8 3 15 1 15 -5 0 -6 6 -9 14 -6 8 3 31
1 51 -4 20 -6 39 -8 42 -5 5 5 -38 17 -104 31 -23 4 -33 3 -33 -5z"/>
<path d="M1274 2480 c-23 -10 -45 -24 -50 -32 -5 -8 2 -6 19 5 15 10 31 16 35
14 4 -3 16 4 26 14 23 23 22 23 -30 -1z"/>
<path d="M1740 2492 c0 -13 40 -33 52 -26 7 4 2 12 -13 20 -28 15 -39 17 -39
6z"/>
<path d="M1810 2463 c0 -6 18 -22 40 -37 22 -14 40 -31 40 -37 0 -5 5 -7 10
-4 6 3 10 -1 10 -10 0 -10 5 -13 12 -9 8 5 9 2 5 -9 -4 -10 -2 -16 5 -14 7 1
14 -7 16 -18 2 -11 9 -26 16 -34 6 -8 19 -37 28 -65 17 -48 17 -49 12 -12 -3
22 -17 62 -30 89 -27 51 -99 137 -117 137 -6 0 -19 7 -29 17 -10 9 -18 12 -18
6z"/>
<path d="M1500 2244 c-13 -33 -13 -834 -1 -867 12 -30 36 -40 62 -27 18 10 19
26 19 450 0 241 -3 445 -6 454 -4 10 -18 16 -35 16 -22 0 -32 -6 -39 -26z m55
-427 c0 -307 -4 -410 -13 -419 -14 -14 -13 -17 -18 77 -3 70 -3 653 1 705 3
51 4 52 18 47 9 -3 12 -94 12 -410z"/>
<path d="M2007 2093 c6 -109 11 -113 12 -10 1 48 -3 87 -7 87 -5 0 -7 -35 -5
-77z"/>
<path d="M2013 1570 c0 -41 2 -58 4 -37 2 20 2 54 0 75 -2 20 -4 3 -4 -38z"/>
<path d="M1635 1383 c-18 -2 -36 -13 -44 -25 -12 -19 -11 -20 6 -14 73 30 268
25 347 -8 32 -13 38 -13 42 -1 9 23 -3 32 -38 30 -18 -2 -37 0 -43 3 -5 4 -17
8 -25 10 -23 5 -212 9 -245 5z"/>
<path d="M1611 1324 c-98 -35 -155 -111 -156 -204 0 -68 18 -106 70 -142 37
-26 149 -58 127 -36 -5 5 -21 10 -36 12 -15 1 -32 8 -38 14 -7 7 -20 12 -30
12 -10 0 -18 5 -18 10 0 6 -6 10 -14 10 -22 0 -58 104 -45 131 3 8 12 32 19
54 13 41 59 90 95 101 11 4 23 11 26 16 3 5 58 12 121 15 96 5 121 3 151 -12
37 -17 47 -38 26 -50 -5 -4 -14 -17 -19 -30 -8 -22 -13 -23 -105 -20 l-96 3
-14 -29 c-21 -40 -19 -44 8 -13 21 25 29 27 85 25 34 -1 74 -6 88 -11 14 -6
27 -10 29 -10 6 0 75 119 75 129 0 17 -80 32 -190 37 -90 3 -125 1 -159 -12z"/>
<path d="M1473 1293 c-39 -51 -64 -108 -68 -151 -1 -13 -8 -27 -14 -32 -9 -5
-4 -9 13 -12 25 -5 26 -3 26 35 0 55 33 120 83 162 39 34 40 35 15 35 -19 0
-35 -11 -55 -37z"/>
<path d="M1956 1218 l-29 -51 42 6 c24 3 44 7 46 9 5 5 -18 88 -25 88 -3 0
-18 -23 -34 -52z"/>
<path d="M1666 1122 c-9 -16 10 -28 84 -52 41 -14 80 -26 85 -26 6 -1 15 -2
20 -3 6 0 22 -12 37 -25 28 -27 47 -25 20 2 -22 22 -85 49 -143 62 -67 14
-101 29 -94 40 3 5 4 10 1 10 -3 0 -7 -4 -10 -8z"/>
<path d="M1941 948 c5 -18 9 -52 7 -75 -1 -23 1 -45 5 -49 7 -7 12 31 9 71 -4
46 -13 85 -22 85 -5 0 -4 -14 1 -32z"/>
<path d="M1676 929 c3 -5 12 -9 20 -9 8 0 23 -9 35 -21 11 -11 23 -18 26 -15
7 7 -23 36 -37 36 -6 0 -9 4 -6 9 3 5 -5 9 -19 9 -14 0 -22 -4 -19 -9z"/>
<path d="M1430 865 c-8 -2 -32 -6 -52 -10 l-38 -6 28 -49 c15 -27 29 -50 32
-50 3 1 17 28 32 60 15 33 24 59 20 59 -4 -1 -14 -3 -22 -4z"/>
<path d="M1452 799 c-18 -39 -32 -74 -32 -78 0 -4 11 -13 25 -19 31 -14 46
-15 26 -3 -21 14 -18 72 5 104 13 18 28 26 49 27 l30 0 -33 20 c-18 11 -34 20
-35 20 -2 0 -18 -32 -35 -71z"/>
<path d="M1563 814 c0 -9 7 -12 19 -8 10 3 18 1 18 -4 0 -9 98 -8 107 2 4 3
-17 6 -45 6 -28 0 -61 4 -75 9 -18 7 -24 6 -24 -5z"/>
<path d="M1935 791 c-3 -12 -10 -19 -14 -16 -5 2 -13 -7 -19 -20 -7 -14 -15
-25 -19 -25 -5 0 -21 -11 -37 -25 -39 -33 -21 -31 29 3 38 26 83 87 72 98 -3
3 -8 -4 -12 -15z"/>
<path d="M1598 653 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M390 1005 c-16 -19 -5 -47 17 -43 22 4 29 43 9 51 -8 3 -20 -1 -26
-8z"/>
<path d="M2209 2512 c38 -31 88 -59 74 -41 -6 8 -31 25 -55 38 -36 19 -39 19
-19 3z"/>
<path d="M2556 2205 c27 -30 49 -55 51 -55 8 0 1 11 -32 46 -19 22 -35 42 -35
45 0 4 -7 10 -15 13 -9 3 5 -19 31 -49z"/>
<path d="M2540 2148 c0 -4 15 -27 33 -51 17 -23 50 -77 71 -120 21 -43 41 -75
44 -73 6 7 -92 183 -123 221 -13 17 -24 27 -25 23z"/>
<path d="M2671 2038 c17 -32 32 -58 34 -58 8 0 -22 63 -42 88 -18 21 -16 14 8
-30z"/>
<path d="M2767 1620 c3 -36 7 -67 9 -69 8 -8 2 84 -6 109 -6 16 -7 1 -3 -40z"/>
<path d="M15 1315 c5 -141 33 -263 93 -409 205 -489 679 -833 1222 -887 583
-58 1129 193 1436 661 129 195 206 415 219 625 5 82 4 87 -9 60 -7 -16 -19
-64 -25 -105 -31 -198 -103 -395 -198 -539 -20 -31 -44 -62 -52 -69 -13 -11
-14 -12 -1 -7 8 4 -2 -9 -22 -29 -21 -20 -38 -40 -38 -44 0 -14 -134 -146
-206 -204 -38 -31 -103 -76 -144 -100 -73 -44 -195 -108 -205 -108 -3 0 -22
-7 -43 -15 -61 -26 -242 -73 -322 -84 -211 -28 -240 -29 -392 -6 -134 20 -153
23 -183 35 -14 5 -29 9 -33 8 -14 -3 -129 36 -199 68 -40 18 -73 31 -73 29 0
-3 -24 10 -52 27 -29 17 -62 36 -73 42 -54 29 -188 132 -255 195 -41 39 -72
71 -68 71 3 0 -8 15 -26 33 -51 53 -71 80 -121 162 -65 108 -86 145 -79 145 3
0 -2 12 -10 28 -14 24 -57 134 -58 146 0 3 -6 27 -14 53 -8 27 -21 84 -29 128
-8 44 -17 91 -21 105 -3 14 -10 43 -15 65 -5 27 -7 1 -4 -80z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
